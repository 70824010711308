ion-content {
    --offset-top: 0rem;
    --background: #FFF;
    font-size: 0.9rem;
}

ion-item {
  --background: #FFF;
}

ion-text {
  font-size: 0.9rem;
}

ion-button {
  text-transform: none;
  --border-color: #AAA;

}


.innerDiv {
    position: absolute;
    top: 1rem; /* Half the innerDiv's height */
    left: 5%; /* Center the innerDiv horizontally */
    transform: translateX(-5%); /* This line is necessary to truly center the innerDiv, as it shifts the innerDiv leftwards by half its width */
    height: 2rem;
    width: 8rem;
    background-color: wheat;
  }

  ion-checkbox {
     --size: 1rem;
    --checkbox-background-checked: #000; 
  }

  ion-content {
    font-size: 1em
  }
  
  ion-checkbox::part(container) {
    border-radius: 0.5rem;
    border: 1px solid #000; 
  }