ion-badge {
    --background: white;
    --color: gray;
    --border: lightgray;
}


.todo {
    display: flex;
    justify-content: center;
    /*border-bottom: 1px solid #EEE;  
    border: 1px solid #EFEFEF; 
    border-radius: 0.5rem;
    background-color: #fff; */
    /*Change to desired border color  */
    /* Change to desired background color */
    padding: 0.2rem 0.2rem;
    height: 100%;
    margin: 0.4rem 0rem;
}

.todo_sub {
    display: flex;
    align-items: center;
    padding-top: 0.2rem;
}

.custom-button {
    text-transform: none;
}

.grow {
    flex-grow: 1;
    align-items: baseline;
    padding-left: 0.6rem;
    height: 100%;
}

ion-header {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

ion-textarea.custom {
    border: none;
    background: transparent;
    color: black;
    font-size: 1.0rem;
    outline: none;
    width: 100%;
}

ion-checkbox1 {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    cursor: pointer;
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .2s ease-in-out;
    border: 1px solid #fff;
}