.app {
  height: 100%;
}

ul {
  list-style: none;
}

li {
  border-radius: 0.5rem;
  /*background-color: #FFF; 
  border: 1px solid #D7E6F5 */
}

li div {
  border-radius: 0.4rem;  /* Adjust as needed */

}

.app > ul > li {
  border-bottom: 1px solid #EEE;
  margin-bottom: 0.2rem;  /*Adjust as needed */
}

.treeRoot {
  box-sizing: border-box;
  height: 100%;
  /* decrease padding at right from 32 to 8px */
  padding: 8px;
}

.draggingSource {
  opacity: 0.3;
}

.placeholderContainer {
  position: relative;
}

.app > ul {
  box-sizing: border-box;
  height: 100%;
  padding-top: 8px;
  padding-left: 8px;
  /* fix the margin at top of tree in the focus card */
  margin: 0px;
}
