.chip-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
  }
  
  /* Optional: Adding some styling for the chips */
  ion-chip {
    margin-right: 10px;
  }
  